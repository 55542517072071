/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */

const rootEpic = combineEpics(
);

function emptyReducer(state = {}) {
  return state;
}

const rootReducer = combineReducers({emptyReducer});

let store;

export function createStore() {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
